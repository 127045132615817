import { render, staticRenderFns } from "./Dropdown.vue?vue&type=template&id=fa0fac72&scoped=true"
import script from "./Dropdown.vue?vue&type=script&lang=js"
export * from "./Dropdown.vue?vue&type=script&lang=js"
import style0 from "./Dropdown.vue?vue&type=style&index=0&id=fa0fac72&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa0fac72",
  null
  
)

export default component.exports